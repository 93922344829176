.home-included {
	padding: 50px 0 70px;
	text-align: center;
	overflow: hidden;

	&__title {
		font: 400 30px/30px $lato;
		text-transform: uppercase;
		margin-bottom: 50px;
	}

	&__list {
		list-style-type: none;
		margin: 0;
	}

	&__item {
		text-align: center;

		@include min-screen(768px) {
			width: 50%;
			float: left;
			min-height: 230px;
		}

		@include min-screen(1024px) {
			width: 20%;
		}

		img {
			margin-bottom: 20px;
		}

		h3 {
			font: 400 19px/24px $lato;
			text-transform: uppercase;
		}
	}
}