.blog {

	&__main {
		@include min-screen(768px) {
			float: left;
			width: 70%;
		}

		&--full-width {
			width: 100%;
		}
	}

	&__sidebar {
		@include min-screen(768px) {
			float: right;
			width: 25%;
			text-align: right;
		}

		h2 {
			font: 500 34px/24px $futura-cond;
			text-transform: uppercase;
			text-shadow: 1px 1px 3px black;
		}

		ul {
			list-style-type: none;

			li {

				a {
					font: 700 18px/24px $lato;
					display: block;
					margin-bottom: 15px;
					text-shadow: 1px 1px 3px black;
				}
			}
		}

		#categories-3 {
			margin: 30px 0;
		}
	}

	&__article {

		&__title {
			margin: 20px 0 0;
		}

		&__date {
			font: 400 14px/18px $lato!important;
			font-style: italic!important;
			margin: 0 0 20px!important;
		}

		img {
			width: 100%;
		}

		&__content {
			margin-top: 30px;
		}

		h2 {
			font: 500 44px/24px $futura-cond;
			text-transform: uppercase;
			color: $pink;
			margin-top: 30px;
		}

		h3 {
			font: 500 34px/24px $futura-cond;
			text-transform: uppercase;
		}

		p {
			font: 400 18px/24px $lato;
		}
	}

	&__comments {
		padding: 30px;

		.comment-reply-title {
			font: 500 34px/24px $futura-cond;
			text-transform: uppercase;
		}

		label {
			display: block;
			clear: both;
			margin-bottom: 10px;
		}

		textarea, input {
			width: 60%;
			border: 0;
			padding: 10px;
		}

		input.submit {
			background: $pink;
			border: 0;
			color: white;
			font: 500 25px/18px $futura-cond;
			text-transform: uppercase;
			padding: 15px 20px;
			box-shadow: 4px 4px 0 0 white;
			margin: 40px 0 0;
			transition: 0.5s;
			outline: 0;
			width: 40%;
			margin-top: 10px;
			&:hover {
				color: $dark-grey;
				background: $green;
			}

			&:focus {
				box-shadow: none;
			}

		}
	}
}