.home-sponsors {
	background: white;
	padding: 50px 0 70px;
	text-align: center;
	overflow: hidden;

	&__logos {
		overflow: hidden;
		padding: 20px 0;

		@include min-screen(678px) {
			width: 620px;
			margin: 0 auto;
		}

		@include min-screen(1000px) {
			width: 920px;
			padding: 40px 0;
			overflow: hidden;
		}
	}

	&__title {
		font: 500 30px/30px $futura-cond;
		text-transform: uppercase;
		color: $dark-grey;
		text-align: center;
		margin-bottom: 30px;
	}

	&__logo {
		
		@include min-screen(768px) {
			margin-top: 40px;
		}

		&--spectrum {
			width: 100%;
			display: block;
			float: left;
			margin-bottom: 40px;

			@include min-screen(678px) {
				width: 200px;
				margin-top: 30px;
				margin-bottom: 20px;
				margin-left: 20px;

			}

			@include min-screen(1000px) {
				width: 300px;
				margin-top: 30px;
				margin-bottom: 20px;
			}
		}

		&--starboard {
			width: 100%;
			display: block;
			float: left;
			margin-bottom: 40px;

			@include min-screen(678px) {
				width: 250px;
				margin-top: 33px;
			}

			@include min-screen(1000px) {
				width: 400px;
				margin-top: 30px;
				margin-bottom: 20px;
			}
		}

		&--multicolour {
			width: 100%;
			display: block;
			float: left;
			margin-bottom: 40px;

			@include min-screen(678px) {
				width: 150px;
    			margin-top: 9px;
			}

			@include min-screen(1000px) {
				width: 200px;
				margin-top: -10px;
			}
		}
	}

	.pink-button {
		clear: both;
		margin-bottom: 40px;
	}
}