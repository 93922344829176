.projects {

	&__article {
		
		@include min-screen(1024px) {
			width: 32%;
			float: left;
			margin-right: 2%;
			min-height: 510px;
		}

		@include min-screen(1136px) {
			min-height: 500px;
		}

		&:nth-of-type(3n) {
			@include min-screen(1024px) {
				float: right;
				margin-right: 0;
			}
		}

		img {
			margin-bottom: 15px;
		}

		p {
			font: 400 16px/24px $lato;
		}
	}
}

.event-title {
	float: left;
	margin-right: 10px;
}

.project-list {
	list-style-type: none;
	margin: 0;
}