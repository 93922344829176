.awards {

	&__award {
		text-align: center;

		@include min-screen(768px) {
			width: 48%;
			float: left;

		}

		@include min-screen(1024px) {
			width: 32%;
			margin-right: 2%;
			min-height: 410px;
			
		}

		@include min-screen(768px) {
			&:nth-of-type(2n) {
				float: right;
				margin-right: 0;
				min-height: 400px;
			}
		}

		@include min-screen(1024px) {
			&:nth-of-type(2n) {
				float: left;
				margin-right: 2%;
				min-height: 410px;
			}
		}

		@include min-screen(1024px) {
			&:nth-of-type(3n) {
				float: right;
				margin-right: 0;
			}
		}
		@include min-screen(1228px) {
			min-height: 350px;

			&:nth-of-type(2n) {
				min-height: 350px;
			}
		}

		img {
			margin: 20px 0;
		}

		&__title {
			font: 500 35px/38px $futura-cond;
			text-transform: uppercase;
		}
	}
}