.header-home {
	background: url('../img/backgrounds/IMG_7807.jpg');
	background-size: cover;
	margin-top: -46px;
	padding-bottom: 50px;
	overflow: hidden;
	text-align: center;
	background-attachment: fixed;

	@include min-screen(768px) {
		text-align: left;
		height: 770px;
		padding-bottom: 0;
	}

	.wrapper {
		position: relative;
		z-index: 2;
	}

	
	&__logo {
		height: 280px;
		width: 280px;
		display: block;
		text-indent: -9999px;
		margin: 0 auto;
		margin-top: 70px;
		clear: both;
		transition: 0.5s;
		border-radius: 50%;
		transition: ease-in-out 0.5s;

		@include min-screen(768px) {
			height: 250px;
			width: 250px;
			display: block;
			text-indent: -9999px;
			position: absolute;
			left: 0;
			top: 80px;
			margin-top: 0;
			background-size: 240px;
		}

		@include min-screen(850px) {
			height: 280px;
			width: 280px;
			background-size: 280px;
		}

		&:hover .logo-green {
			fill: #84920C;
			transition: ease-in-out 0.5s;
		}
	}

	

	&__menu-open {
		display: block;
		position: absolute;
		top: -18px;
    	right: -7px;
		font-size: 35px;

		@include min-screen(768px) {
			display: none;
		}

	}

	&__menu-close {
	    display: block;
	    height: 30px;
	    width: 30px;
	    position: absolute;
	    right: 8%;
	    z-index: 9999;
	    font-size: 40px;
	    top: 13px;

	    @include min-screen(768px) {
	    	display: none;
	    }
	}

	&__menu {
		margin: 0;
	    width: 100%;
	    position: fixed;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    padding: 30px;
	    z-index: 9999;
	    min-height: 100%;
	    height: 100%;
	    background: rgba(17,17,17, .9);

		@include min-screen(768px) {
			position: absolute;
			right: 0;
			left: auto;
			bottom: auto;
			top: 80px;
			height: auto;
			width: auto;
			padding: 0;
			background: transparent;
		}

		ul {
			list-style-type: none;
			margin: 0;

			li {
				text-align: left;
				position: relative;

				@include min-screen(768px) {
					display: block;
					float: left;
				}

				&.current-menu-item a, &.current-menu-parent a {
					color: $green;
					position: relative;
				}

				&.current-menu-parent {
					ul {
						li {
							a {
								color: white;
							}

							&.current_page_item a {
								color: $green;
							}
						}
					}
				}

				a {
					color: white;
					font: 500 30px/20px $futura-cond;
					text-transform: uppercase;
					padding: 12px 0 16px;
					display: block;
					position: relative;
					
					@include min-screen(768px) {
						margin-left: 15px;
						font: 500 17px/20px $futura-cond;
						float: left;	
					}

					@include min-screen(820px) {
						margin-left: 15px;
						font: 500 20px/20px $futura-cond;
						float: left;	
					}

					@include min-screen(1024px) {
						margin-left: 28px;
						font: 500 24px/20px $futura-cond;
						float: left;
					}

					&:hover, &:focus, &:active {
						color: $green;
						position: relative;
					}
				}

				&:hover .sub-menu {
					display: block;
				}

				&.menu-item-has-children {

					@include min-screen(768px) {
						padding-right: 20px;
						background: url('../img/arrow-down.png') no-repeat 100% 50%;
					}
				}
			}

			.sub-menu {
				margin-left: 20px;


				@include min-screen(768px) {
					position: absolute;
					top: 40px;
					left: 20px;
					background: $dark-grey;
					width: 195px;
					margin: 0;
					display: none;

					li {
						display: block;
						float: none;

						a {
							display: block;
							float: none;
							padding: 15px 20px;
							margin: 0;

							&:hover {
								background: $green;
								color: black;
							}
						}
					}
				}
			}
		}
	}

	&__details {
		margin-top: 40px;
		overflow: hidden;
		
		@include min-screen(768px) {
			position: absolute;
			top: 450px;
			margin: 0;
		}

		li {
			float: left;
			display: block;
			margin-right: 15px;
			padding-left: 35px;
			text-align: left;
			font: 500 27px/25px $futura-cond;
			margin-bottom: 15px;
			text-transform: uppercase;

			@include min-screen(768px) {
				font: 500 22px/25px $futura-cond;

			}

			@include min-screen(885px) {
				margin-right: 25px;
				font: 500 25px/25px $futura-cond;
			}

			a {
				color: white;

				&:hover {
					color: $green;
				}
			}
		}

		&__date {
			background: url('../img/time.png') no-repeat 0% 0%;
			background-size: 25px;
		}

		&__location {
			background: url('../img/location.png') no-repeat 0% 0%;
			background-size: 25px;
		}

		&__price {
			background: url('../img/price.png') no-repeat 0% 0%;
			background-size: 25px;
		}
	}

	&__title {
		font: 500 50px/47px $futura-cond;
		text-transform: uppercase;
		display: block;
		clear: both;
		margin-top: 20px;

		@include min-screen(768px) {
			position: absolute;
			top: 510px;
			font: 500 52px/57px $futura-cond;
		}

		@include min-screen(992px) {			
			font: 500 70px/57px $futura-cond;
		}
	}

	&__subtitle {
		font: 300 23px/25px $lato;
		

		@include min-screen(768px) {
			font: 300 28px/32px $lato;
			position: absolute;
			top: 600px;
		}
	}

	&__button {
		background: $green;
		font: 500 31px/25px $futura-cond;
		color: $dark-grey;
		border: 0;
		padding: 10px 20px;
		text-transform: uppercase;
    	box-shadow: 4px 4px 0 0 #FFF;
    	transition: 0.5s;
    	outline: none;
    	width: 192px;
    	margin: 40px auto 0;
    	display: block;

    	@include min-screen(768px) {
    		position: absolute;
	    	right: 0;
	    	top: 150px;
    	}

    	&:hover {
    		background: $pink;
    		transition: 0.5s;
    		color: white;
    	}

    	&:focus {
    		box-shadow: none;
    		background: $pink;
    		color: white;
    	}
    	&--grey {
			background: #666;

			&:hover {
				background: #666;
				color: $dark-grey;
			}
		}
	}
}


.logo:hover {
	.logo-green {
	fill: $pink;
	}
}

.overlay {
	background: rgba(0,0,0,0.7);
	position: absolute;
	z-index: 1;
	width: 100%;

	@include iphone5 {
		height: 979px;
	}

	@include min-screen(375px) {
		height: 908px;
	}

	@include min-screen(414px) {
		height: 882px;
	}

	@include min-screen(469px) {
		height: 857px;
	}

	@include min-screen(595px) {
		height: 817px;
	}

	@include min-screen(724px) {
		height: 770px;
	}

	@include min-screen(768px) {
		height: 770px;
	}
}