.home-about {
	background: $green;
	padding: 50px 0;
	overflow: hidden;

	&__title {
		text-transform: uppercase;
		color: black;
		font: 500 33px/33px $futura-cond;
		margin-bottom: 10px;

		@include min-screen(1280px) {
			font: 500 38px/33px $futura-cond;
		}
	}

	&__item {
		color: black;
		font: 500 21px/33px $futura-cond;
		margin-bottom: 5px;

		@include min-screen(1024px) {
			font: 500 26px/33px $futura-cond;
		}

		@include min-screen(1280px) {
			font: 500 29px/33px $futura-cond;
			margin-bottom: 15px;
		}

		.title {
			text-transform: uppercase;
			width: 72px;
			display: block;
			float: left;
			font-weight: bold;

			@include min-screen(1280px) {
				width: 110px;
			}
		}
	}

	&__right {
		margin-bottom: 40px;

		@include min-screen(768px) {
			float: right;
			width: 50%;
			padding: 0 0 0 50px;
		}
	}

	&__video {

		@include min-screen(768px) {
			width: 50%;
			float: left;
		}
	}
}