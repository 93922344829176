.programme {

	&__text {
		font: 400 25px/32px $lato;
		
		margin: 0 auto 30px;
		overflow: hidden;
		clear: both;

		@include min-screen(768px) {
			width: 50%;
		}
	}

	&__time {
		font: 500 25px/35px $futura-cond;
		text-transform: uppercase;
		width: 20%;
		display: block;
		float: left;
	}

	&__right {
		float: left;
		display: block;
		width: 80%;
	}
}