.winners {

	&__title {
		font: 500 45px/35px $futura-cond;
		text-transform: uppercase;
		margin-bottom: 5px;
		display: block;
		width: 100%;
		clear: both;
	}

	&__subtitle {
		font: 400 30px/30px $lato; 
		margin-bottom: 10px;
	}

	&__winner {
		margin-top: 20px;
		overflow: hidden;
		min-height: 180px;

		@include min-screen(1024px) {
			float: left;
			width: 48%;
			margin-right: 2%;
		}

		&__left {

			width: 50%;
			float: left;

			@include min-screen(768px) {
				width: 40%;
			}

			@include min-screen(1024px) {
				width: 50%;
			}
		}

		&__right {
			float: left;
			width: 50%;

			@include min-screen(768px) {
				width: 60%;
			}

			@include min-screen(1024px) {
				width: 50%;
			}
		}

		&__award {
			text-transform: uppercase;
			font: 500 35px/35px $futura-cond;
			color: $pink;
		}

		&__text {
			margin: 0 0 10px;
			font: 400 16px/24px $lato; 
		}

		.small-button {
			margin-top: 15px;
		}
	}
}