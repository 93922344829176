.page-background {
	padding: 50px 0;
	overflow: hidden;

	@include min-screen(768px) {
		padding: 120px 0;
	}

	&--about {
		background: url('../img/backgrounds/IMG_7784.jpg') no-repeat;
		background-size: cover;
		background-attachment: fixed;
	}

	&--awards {
		background: url('../img/backgrounds/IMG_7785.jpg') no-repeat;
		background-size: cover;
		background-attachment: fixed;
	}

	&--judges {
		background: url('../img/backgrounds/IMG_7807.jpg') no-repeat;
		background-size: cover;
		background-attachment: fixed;
	}

	&--programme {
		background: url('../img/backgrounds/coastguard.jpg') no-repeat;
		background-size: cover;
		background-attachment: fixed;
	}

	&--blog {
		background: url('../img/backgrounds/coastguard.jpg') no-repeat;
		background-size: cover;
		background-attachment: fixed;
	}
}