h1 {
	font: 500 35px/38px $futura-cond;
	text-transform: uppercase;
}

h2 {

}

h3 {

}

.main-title-centre {
	font: 500 55px/38px $futura-cond;
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 30px;
}

.main-title-right {
	font: 500 55px/38px $futura-cond;
	text-transform: uppercase;
	text-align: right;
	margin-bottom: 30px;
	
	@include min-screen(768px) {
		margin-top: -50px;
	}
}