.about {

	&__left {
		@include min-screen(768px) {
			float: left;
			width: 49%;
		}	

		p {
			font: 400 16px/23px $lato;
			margin-bottom: 10px;
		}
	}

	&__right {
		@include min-screen(768px) {
			float: right;
			width: 49%;
		}	

		&__youtube {

			p {
				font: 400 20px/28px $lato;
				margin: 0;
			}

			&__title {
				text-transform: uppercase;
				font: 700 24px/28px $lato;
				margin-bottom: 10px;
			}
		}
	}

	&__main-title {
		font: 500 35px/38px $futura-cond;
		margin: 0 0 10px;
	}

	&__sub-title {
		font: 500 30px/38px $futura-cond;
		text-transform: uppercase;
		margin: 20px 0 10px;
	}

	&__team {
		clear: both;
		overflow: hidden;
		padding: 20px 50px 40px;

		&__title {
			text-align: center;
			font: 500 35px/38px $futura-cond;
			text-transform: uppercase;
			margin-top: 20px;
		}

		&__text {
			text-align: center;
			font: 400 18px/28px $lato;
		}

		&__member {
			text-align: center;
			margin-bottom: 40px;

			@include min-screen(768px) {
				width: 50%;
				float: left;
				padding: 0 30px;
				min-height: 400px;
			}

			@include min-screen(1024px) {
				width: 25%;
			}

			&__image {
				border-radius: 350px;
				width: 100%;
			}

			&__name {
				font: 700 24px/28px $lato;
				margin: 20px 0 0;
			}

			&__position {
				font: 400 20px/28px $lato;
				font-style: italic;
				margin: 0;
			}

			&__text {
				font: 400 14px/18px $lato;
				margin:10px  0;
			}
		}
	}
}