.venue {

	&__title {
		font: 500 55px/38px $futura-cond;
	}

	&__image {
		width: 100%;

		&--small {
			@include min-screen(768px) {
				width: 49%;
				float: left;
				margin-top: 10px;
			}

			&:nth-of-type(2n) {
				@include min-screen(768px) {
					float: right;
				}
			}
		}
	}

	&__left {
		margin-bottom: 30px;

		@include min-screen(768px) {
			float: left;
			width: 25%; 
		}
	}

	&__right {
		@include min-screen(768px) {
			float: right;
			width: 70%; 
		}
	}
}