.header-main {
	background: black;
	height: auto;
	margin-top: -40px;
	text-align: center;
	padding-bottom: 30px;
	
	@include min-screen(768px) {
		height: 110px;
		margin-top: 0;
		padding-bottom: 0;
	}

	&__menu {

		@include min-screen(768px) {
			top: 30px;
			right: 215px;
		}
	}

	&__button {
		position: absolute;
		right: 0;
		top: 30px;
		font: 500 27px/25px $futura-cond;
		padding: 10px 10px;
		width: 165px;

		@include min-screen(768px) {
			right: 0;
			top: -5px;
			padding: 5px 10px;
			font: 500 25px/25px $futura-cond;
		}

		&--grey {
			background: #666;
			width: 189px;
			font-size: 25px;

			@include min-screen(768px) {
				width: 189px;
			}

			&:hover {
				background: #666;
				color: $dark-grey;
			}
		}
	}

	&__logo {
		margin: 60px 0 0;
		width: 120px;
		height: 120px;

		@include min-screen(768px) {
			width: 180px;
			height: 180px;
			top: 20px;
			margin: 0;
		}
	}
}