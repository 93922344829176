html {
    font-size: ($base-font-size / 16px) * 1em; /* [1] */
    line-height: $base-line-height / $base-font-size; /* [1] */
    padding: 0;
    background-color: $dark-grey;
    color: $color-text;
    -webkit-font-smoothing: antialiased;
    margin: 0;
}

body {
    margin: 0;
    padding: 0;
}
.small {

}

.medium {

}

.large {

}

.hidden {
    position: absolute;
    left: -9999px;
}

.alignright {
    float: right;
    margin: 0 0 20px 20px;
}

.alignleft {
    float: left;
    margin: 0 20px 20px 0;
}

p {
    font: 300 18px/24px $lato;
}

.center {
    width: 100%;
    text-align: center;
}

.clear {
    clear: both;
    display: block;
}

.small-divider {
    display: block;
    background: black;
    width: 20px;
    height: 4px;
    clear: both;
    margin-bottom: 20px;

    &--center {
        margin: 0 auto;
    }

    &--white {
        background: white;
    }
}

.transparent {
    background: rgba(0,0,0,0.9);
    padding: 20px 30px;
    margin-bottom: 20px;
}