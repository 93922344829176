.small-button {
	display: inline-block;
	text-transform: uppercase;
	font: 500 14px/18px $futura-cond;
	color: black;
	background: white;
	padding: 5px 10px;

	@include min-screen(1024px) {
		font: 500 17px/18px $futura-cond;
	}

	&:hover, &:focus, &:active {
		background: $pink;
		color: white;
	}
}

.pink-button {
	background: $pink;
	border: 0;
	color: white;
	font: 500 25px/18px $futura-cond;
	text-transform: uppercase;
	padding: 10px 20px;
	box-shadow: 4px 4px 0 0 $dark-grey;
	margin: 40px 0 0;
	transition: 0.5s;
	outline: 0;

	&:hover {
		color: $dark-grey;
		background: $green;
	}

	&:focus {
		box-shadow: none;
	}
}