.wrapper {
	width: 85%;
	margin: 0 auto;
	position: relative;

	@include min-screen(1000px) {
		max-width: 1100px;
	}
}

.page-wrapper {

}

.hidden-mob {
	display: none;

	@include min-desktop {
		display: block;
	}
}

.videowrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videowrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}