.project {

	&__article {
		padding-top: 30px;
		padding-bottom: 50px;

		img {
			width: 100%;
		}
	}

	&__main-image {
		margin-bottom: 20px;

		@include min-screen(768px) {
			float: left;
			width: 50%;
			margin: 0 30px 40px 0;
		}
	}
}