.footer-main {
	padding: 50px 0;

	&__newsletter {
		background: #222222;
		padding: 20px;
		overflow: hidden;
		
		@include min-screen(768px) {
			width: 100%;
			margin: 0 auto;
		}

		&__title {
			text-transform: uppercase;
			font: 500 25px/20px $futura-cond;
			text-align: center;

			@include min-screen(768px) {
				float: left;
				display: block;
				border-right: 1px solid white;
				padding-right: 20px;
				margin: 9px 20px 0 0;
				font: 500 24px/20px $futura-cond;
				width: 25%;
			}

			@include min-screen(1024px) {
				font: 500 24px/20px $futura-cond;
			}

			@include min-screen(1171px) {
				font: 500 28px/28px $futura-cond;
			}
		}

		&__field {

			@include min-screen(768px) {
				float: left;
			    margin-right: 11px;
    			width: 29.3%;
    			margin-top: 10px;
			}

			@include min-screen(980px) {
				float: left;
			    margin-right: 11px;
    			width: 30.3%;
    			margin-top: 10px;
			}

			label {
				position: absolute;
				left: -9999px;
			}

			input {
				border: 0;
				padding: 10px;
				width: 100%;
				margin-bottom: 20px;
				color: black;

				@include min-screen(1171px) {
					padding: 20px;
				}
			}
		}

		&__button {

			button {
				background: $green;
				color: black;
				padding: 10px 20px;
				text-transform: uppercase;
				width: 50%;
				margin: 0 auto;
				display: block;
				font: 500 20px/20px $futura-cond;
				border: 0;
				transition: 0.5s;

				@include min-screen(768px) {
					width: 9%;
					float: left;
					padding: 10px 17px;
					margin-top: 10px;
				}

				@include min-screen(1171px) {
					padding: 19px 20px;
				}

				&:hover {
					background: $pink;
					transition: 0.5s;
					color: white;
				}
			}
		}
	}

	&__nav {
		margin: 30px 0;
		overflow: hidden;
		clear: both;

		ul {
			list-style-type: none;
			margin: 0 auto;
			display: table;

			li {
				float: left; 
				display: inline-block;

				a {
					display: inline-block;
					float: left;
					color: white;
					text-transform: uppercase;
					font: 500 20px/20px $futura-cond;
					padding: 5px 10px;

					&:hover {
						color: $pink;
					}
				}
			}
		}
	}

	&__social {
		width: 119px;
		margin: 0 auto;
		overflow: hidden;
		clear: both;

		ul {
			list-style-type: none;
			margin: 0;

			li {
				display: block;
				float: left;

				a {
					font-size: 25px;
					display: block;
					float: left;
					padding: 0 10px;
				}
			}
		}
	}

	&__copyright {
		text-align: center;
		font: 300 13px/18px $lato;
		margin-top: 40px;
	}
}